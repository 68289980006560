/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getGithubGistInfo } from '../../utils/url';
import { _window } from '../../utils';

interface IGithubGistProps {
  url: string;
}
const GithubGist: React.FC<IGithubGistProps> = ({ url }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    const { id, embeddedScriptUrl } = getGithubGistInfo(url);
    (_window as any)[id] = (gist: { stylesheet: string; div: string }) => {
      setContent(gist.div.replace(/href=/g, 'target="_blank" href='));

      if (!document.head.innerHTML.includes(gist.stylesheet)) {
        const stylesheet = document.createElement('link');
        stylesheet.href = gist.stylesheet;
        stylesheet.type = 'text/css';
        stylesheet.rel = 'stylesheet';
        document.head.appendChild(stylesheet);
      }
      delete (_window as any)[id];
    };

    const script = document.createElement('script');
    script.src = embeddedScriptUrl;
    document.head.appendChild(script);
  }, [url]);

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default GithubGist;
