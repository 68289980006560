import * as React from 'react';
import { useEffect, useRef } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-tsx';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-yaml';

interface IHighlightedCodeProps {
  code: string;
  lang: string;
}

const HighlightedCode: React.FC<IHighlightedCodeProps> = ({
  code,
  lang,
  children,
}) => {
  const codeEl = useRef<HTMLPreElement>(null);
  useEffect(() => {
    if (codeEl.current) {
      Prism.highlightElement(codeEl.current);
    }
  }, [children]);
  return (
    <pre
      style={{ marginBottom: '30px' }}
      ref={codeEl}
      className={`language-${lang}`}
    >
      <code>{code}</code>
    </pre>
  );
};

export default React.memo(HighlightedCode);
