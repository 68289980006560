import * as React from 'react';
import { graphql } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import styled from 'styled-components';
import Date from '../components/Date';
import Layout from '../components/Layout';
import { BaseContainer } from '../components/baseComponents';
import BlogRichText from '../components/BlogRichText';
import { BlogPostBySlugQuery } from '../../gatsby-graphql';
import { colors, fonts, mq } from '../styles/theme';
import BlogPostCategoriesList from '../components/BlogPostCategoriesList';
import { IPage } from '../types';

const BlogPost: React.FC<IPage<BlogPostBySlugQuery>> = ({
  data: {
    post,
    contentfulNavigationItem,
    siteNavigation,
    footerSocialLinkLabel,
  },
  pageContext: { locale },
  location: { pathname },
}) => {
  const sectionName = String(contentfulNavigationItem?.name);
  return (
    <Layout
      lang={locale}
      pagePathName={pathname}
      pageImage={post?.heroImage?.file?.url as string}
      pageTitle={`${post?.title} | ${sectionName}`}
      pageDescription={post?.shortDescription?.shortDescription as string}
      section={contentfulNavigationItem}
      siteNavigation={siteNavigation}
      footerSocialLabel={footerSocialLinkLabel?.value as string}
    >
      <StyledContainer>
        <h1>{post?.title}</h1>
        <BlogPostCategoriesList locale={locale} categories={post?.categories} />
        <DateContainer>
          <StyledDate date={post?.createdAt} locale={locale} />
        </DateContainer>
        <StyledGatsbyImage
          fluid={post?.heroImage?.fluid as FluidObject}
          draggable={false}
        />
        <RichTextContainer>
          <BlogRichText document={post?.rif?.json} />
        </RichTextContainer>
      </StyledContainer>
    </Layout>
  );
};

const StyledContainer = styled(BaseContainer)`
  max-width: 800px;
  h1 {
    margin-top: 2rem;
    margin-bottom: 0.25rem;
    font-size: 32px;
    ${mq.tablet} {
      margin-top: 3rem;
      font-size: 40px;
    }
  }
`;

const DateContainer = styled.div`
  margin-top: 0.75rem;
`;

const StyledDate = styled(Date)`
  font-family: ${fonts.sansSerif};
  font-weight: 400;
  color: ${colors.text.grey};
  text-transform: capitalize;
  margin-top: 1rem;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  margin-top: 1.5rem;
`;

const RichTextContainer = styled.div`
  margin-top: 42px;
  margin-bottom: 60px;
  p {
    font-size: 18px;
    line-height: 28px;
    word-break: break-word;
    font-weight: 400;
    letter-spacing: -0.003em;
    color: rgba(0, 0, 0, 0.84);
    ${mq.tablet} {
      font-size: 20px;
      line-height: 32px;
    }
  }
`;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $locale: String!
    $sectionSlug: String!
  ) {
    ...PageInfoFragment
    contentfulNavigationItem(
      slug: { eq: $sectionSlug }
      node_locale: { eq: $locale }
    ) {
      ...NavigationSectionInfo
    }
    post: contentfulBlogPost(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      title
      slug
      createdAt
      shortDescription {
        shortDescription
      }
      rif {
        json
      }
      categories {
        ...BlogPostCategoriesInfo
      }
      heroImage {
        file {
          url
        }
        description
        fluid(
          maxWidth: 1920
          resizingBehavior: FILL
          cropFocus: CENTER
          quality: 100
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;

export default BlogPost;
