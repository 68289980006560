/**
 *
 * GITHUB
 */

export const isGithubGistUrl = (url: string): boolean => {
  const regex = new RegExp(
    /https:\/\/gist.github.com\/([\w]+)\/([\w]+)?(#file-[\w\-]+)?$/g
  );
  return regex.test(url);
};

interface IGetGithubGistInfo {
  gist: string;
  file: string;
  id: string;
  embeddedScriptUrl: string;
}

export const getGithubGistInfo = (url: string): IGetGithubGistInfo => {
  const urlInfo = url.split('.com/')[1].split('#');
  const gist = urlInfo[0];
  const file = urlInfo[1].replace('file-', '');
  const id = `gist${gist.replace('/', '')}`;
  const embeddedScriptUrl = `https://gist.github.com/${gist}.json?callback=${id}${
    file ? `&file=${file}` : ''
  }`;
  return {
    gist,
    file,
    id,
    embeddedScriptUrl,
  };
};

/**
 *
 * YOUTUBE
 */

export const isEmbeddedYoutubeUrl = (url: string): boolean => {
  const regex = new RegExp(/https:\/\/www.youtube.com\/embed\/([\w\-]+)?$/g);
  return regex.test(url);
};

/**
 *
 * CONTENFUL
 */

/**
 *
 * @description Remove string used to avoid embedding ($not-embed)
 */
export const sanitizeContentfulLink = (url: string): string =>
  url.replace('$not-embed', '');
