import { IContentfulRifAsset, INodeType } from '../types';

export const getContentType = <T>(nodeType: INodeType<{}, T>) =>
  nodeType.data.target.sys.contentType.sys.contentful_id;

export const getFields = <T, F>(node: INodeType<{}, F>) =>
  (node as INodeType<T, F>).data.target.fields;

export const getAssetType = <T>(nodeType: INodeType<IContentfulRifAsset, T>) =>
  nodeType.data.target.fields.file.contentType;
