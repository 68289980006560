import * as React from 'react';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import BlogEmbeddedEntry from './CustomBlocks/BlogEmbeddedEntry';
import InlineHyperlink from './CustomInlines/InlineHyperlink';
import BlogEmbeddedAsset from './CustomBlocks/BlogEmbeddedAsset';

interface IBlogRichText {
  document: Document;
}

const BlogRichText: React.FC<IBlogRichText> = ({ document }) => {
  const options: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: BlogEmbeddedEntry,
      [BLOCKS.EMBEDDED_ASSET]: BlogEmbeddedAsset,
      [INLINES.HYPERLINK]: InlineHyperlink,
    },
  };
  return <>{documentToReactComponents(document, options)}</>;
};

export default BlogRichText;
