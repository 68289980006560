import React from 'react';
import {
  blogEntryBlockTypes,
  IContentfulRifAsset,
  INodeType,
} from '../../../Contenful/types';
import { getAssetType, getFields } from '../../../Contenful/utils';
import { BaseRifAssetContainer } from '../../baseComponents';

const BlogEmbeddedAsset = (cNode: {}) => {
  const node = cNode as INodeType<IContentfulRifAsset, blogEntryBlockTypes>;
  const assetType = getAssetType(node);
  if (assetType.includes('image')) {
    const asset = getFields<IContentfulRifAsset, {}>(node);
    return (
      <BaseRifAssetContainer>
        <img src={asset.file.url} alt={asset.title} />
      </BaseRifAssetContainer>
    );
  }
  return null;
};

export default BlogEmbeddedAsset;
