export interface IFieldType<T, F> {
  sys: {
    contentType: {
      sys: {
        id: F;
        contentful_id: F;
      };
    };
  };
  fields: T;
}

export interface IContentfulRifAsset {
  file: {
    url: string;
    fileName: string;
    contentType: string;
  };
  title: string;
}

export interface INodeType<T, F> {
  nodeType: string;
  content: [];
  data: {
    target: IFieldType<T, F>;
  };
}

export const enum blogEntryBlockTypes {
  codeSnippet = 'codeSnippet',
  blogPost = 'blogPost',
}
