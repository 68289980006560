import * as React from 'react';
import { Hyperlink } from '@contentful/rich-text-types';
import styled from 'styled-components';
import {
  isEmbeddedYoutubeUrl,
  isGithubGistUrl,
  sanitizeContentfulLink,
} from '../../../utils/url';
import GithubGist from '../../GithubGist';

const InlineHyperlink = (node: {}) => {
  const {
    data: { uri },
    content,
  }: Hyperlink = node as Hyperlink;
  const title = content[0] && content[0].value;
  if (isEmbeddedYoutubeUrl(uri)) {
    return (
      <IframeContainer>
        <iframe
          title={title}
          src={uri}
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen
        />
      </IframeContainer>
    );
  } else if (isGithubGistUrl(uri)) {
    return <GithubGist url={uri} />;
  }
  return (
    <StyledHyperLink
      href={sanitizeContentfulLink(uri)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {title}
    </StyledHyperLink>
  );
};

const IframeContainer = styled.span`
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const StyledHyperLink = styled.a`
  color: inherit;
`;

export default InlineHyperlink;
