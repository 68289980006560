import React from 'react';
import HighlightedCode from '../../HighlightedCode';
import { getContentType, getFields } from '../../../Contenful/utils';
import { blogEntryBlockTypes, INodeType } from '../../../Contenful/types';
import { ContentfulBlogPostRifRichTextNodeContentDataTargetFields } from '../../../../gatsby-graphql';

const BlogEmbeddedEntry = (cNode: {}) => {
  const node = cNode as INodeType<{}, blogEntryBlockTypes>;
  const contentType = getContentType(node);
  switch (contentType) {
    case blogEntryBlockTypes.codeSnippet: {
      const data = getFields<
        Pick<
          ContentfulBlogPostRifRichTextNodeContentDataTargetFields,
          'body' | 'lang'
        >,
        blogEntryBlockTypes
      >(node);
      return (
        <HighlightedCode
          code={data.body as string}
          lang={data.lang as string}
        />
      );
    }
  }
};

export default BlogEmbeddedEntry;
